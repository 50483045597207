<template>
  <div class="script-execute-container">
    <div class="module-header" style="margin-bottom: 24px">脚本执行</div>

    <a-form-model
      ref="form"
      :label-col="{
        span: 2,
        offset: 4,
        style: {
          textAlign: 'left'
        }
      }"
      :model="form"
      :rules="rules"
      :wrapper-col="{
        span: 14
      }"
      style="margin-top: 32px;"
    >
      <a-form-model-item label="任务名称" prop="name">
        <a-input
          v-model="form.name"
          :max-length="128"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="目标主机" prop="host">
        <multi-source-select
          v-model="form.host"
          source-type="os"
        ></multi-source-select>
      </a-form-model-item>
      <a-form-model-item label="脚本来源" prop="souce">
        <a-radio-group style="background: #fff;" :options="souceOptions" v-model="form.souce" :disabled="!!scriptId"/>
      </a-form-model-item>
      <a-form-model-item label="选择脚本" prop="selectScript" v-if="form.souce==='store'">
        <script-select :value="form.selectScript" @input="handleChange" :disabled="!!scriptId"></script-select>
      </a-form-model-item>
      <!-- <a-form-model-item label="脚本分组" prop="groups" v-if="form.souce === 'store'">
        <group-select
          v-model="form.groups"
          source-type="script"
          :disabled="true"
        ></group-select>
      </a-form-model-item> -->
      <a-form-model-item prop="module" label="脚本类型">
        <a-radio-group style="background: #fff;" :options="moduleOptions" v-model="form.module" :disabled="!!scriptId"/>
      </a-form-model-item>
      <a-form-model-item label="脚本内容" prop="content">
        <code-mirror v-model="form.content" :readMode="readMode"></code-mirror>
      </a-form-model-item>
      <a-form-model-item label="超时时间" prop="timeout">
        <a-input-number
          v-model="form.timeout"
          :min="5"
          :max="30"
          style="width: 100%;"></a-input-number>
      </a-form-model-item>
<!--      <a-form-model-item label="参数脚本" prop="param">-->
<!--        <a-input-->
<!--          v-model="form.param"-->
<!--          placeholder="请输入参数脚本"-->
<!--          :max-length="128"-->
<!--        ></a-input>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="执行原因" prop="reason">-->
<!--        <a-input-->
<!--          v-model="form.reason"-->
<!--          placeholder="请输入执行原因"-->
<!--          :max-length="128"-->
<!--        ></a-input>-->
<!--      </a-form-model-item>-->
      <a-form-model-item
        :wrapper-col="{ span: 16, offset: 10 }"
        style="margin-bottom: 0;"
      >
        <a-space size="middle">
          <a-button  type="primary" @click="handleOk" :loading="loading">
            执行脚本
          </a-button>
          <a-button @click="handleCancel">取消</a-button>
        </a-space>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { getScript } from '@/api/script'
import { executeScript } from '@/api/script-task'
// import GroupSelect from '@/components/select/GroupSelect'
import CodeMirror from '@/components/CodeMirror'
import ScriptSelect from '@/components/select/ScriptSelect'
import MultiSourceSelect from '@/components/select/MultiSourceSelect'

export default {
  name: 'ScriptExecute',
  components: {
    // GroupSelect,
    CodeMirror,
    ScriptSelect,
    MultiSourceSelect
  },
  computed: {
    readMode () {
      if (this.scriptId) {
        return 'nocursor'
      } else if (this.form.souce === 'store') {
        return 'nocursor'
      } else {
        return false
      }
    }
  },
  data () {
    return {
      loading: false,
      moduleOptions: [
        { label: 'Shell', value: 'shell' }
      ],
      souceOptions: [
        { label: '手工输入', value: 'input' },
        { label: '脚本仓库', value: 'store' }
      ],
      form: {
        id: '',
        name: '',
        host: undefined,
        selectScript: undefined,
        param: '',
        module: 'shell',
        reason: '',
        souce: 'input',
        timeout: 30,
        content: '',
        script_id: '',
        manual: true
      },
      rules: {
        name: [
          {
            message: '请输入任务名称',
            required: true,
            trigger: 'blur'
          }
        ],
        host: [
          {
            message: '请选择目标主机',
            required: true,
            trigger: 'change'
          }
        ],
        souce: [
          {
            message: '请选择脚本来源',
            required: true,
            trigger: 'change'
          }
        ],
        content: [
          {
            message: '请输入脚本内容',
            required: true,
            trigger: 'blur'
          }
        ],
        module: [
          {
            message: '请选择脚本类型',
            required: true,
            trigger: 'change'
          }
        ],
        selectScript: [
          {
            message: '请选择脚本',
            required: true,
            trigger: 'change'
          }
        ],
        over_time: [
          {
            message: '请输入超时时间',
            required: true,
            trigger: 'blur'
          }
        ]
      },
      scriptId: this.$route.params.id,
      taskId: ''
    }
  },
  mounted () {
    if (this.scriptId) {
      getScript(this.scriptId).then(res => {
        this.form.script_id = this.scriptId
        const data = res.data
        this.form.groups = data.groups
        this.form.module = data.module
        this.form.content = data.content
        this.form.souce = 'store'
        this.form.selectScript = { name: data.name, id: data.id }
      })
    }
  },
  methods: {
    handleChange (v) {
      this.form.selectScript = v
      this.form.script_id = v.id
      // 获取脚本内容
      getScript(v.id).then(res => {
        this.form.content = res.data.content
        this.form.groups = res.data.groups
      })
    },
    handleOk () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          if (this.form.timeout > 30) this.form.timeout = 30
          else if (this.form.timeout < 0) this.form.timeout = 0
          const params = {
            name: this.form.name,
            timeout: this.form.timeout,
            source_type: 'os',
            source_ids: this.form.host.map(host => host.id),
            source_target: 'selected',
            manual: this.form.souce === 'input'
          }
          if (params.manual) params.content = this.form.content
          else params.script_id = this.form.script_id
          executeScript(params).then(res => {
            this.taskId = res.data.id
          }).finally(() => {
            setTimeout(() => {
              this.loading = false
              this.$router.push({ name: 'ScriptExecuteDetail', params: { id: this.taskId } })
            }, 500)
          })
        }
      })
    },
    handleCancel () {

    }
  }
}
</script>

<style lang="less">
.script-execute-container {
  border-radius: 8px;
  background: #fff;
  min-height: calc(100vh - 158px);
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 8px;
  padding: 18px;

  .CodeMirror {
    height: 250px !important;
  }
}
</style>
