<template>
  <div class="code-mirror-container">
    <div style="height: 32px; background: #fff; line-height: 32px; padding-left: 8px; color: #d9d9d9;">
      <a-icon type="code" />
    </div>
    <codemirror :value="value" :options="cmOptions" @input="handleChange"></codemirror>
  </div>
</template>

<script>
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'

// 样式
import 'codemirror/theme/lesser-dark.css'

// 括号显示匹配
import 'codemirror/addon/edit/matchbrackets'
import 'codemirror/addon/selection/active-line'

// 括号、引号编辑和删除时成对出现
import 'codemirror/addon/edit/closebrackets'

// 折叠代码
import 'codemirror/addon/fold/brace-fold'
import 'codemirror/addon/fold/foldcode'
import 'codemirror/addon/fold/foldgutter.css'
import 'codemirror/addon/fold/foldgutter.js'
import 'codemirror/addon/fold/indent-fold.js'

// 语法
import 'codemirror/mode/shell/shell.js'

export default {
  name: 'CodeMirror',
  components: {
    codemirror
  },
  props: {
    language: {
      type: String,
      default: 'text/x-sh'
    },
    value: {
      type: String
    },
    readMode: {
      type: [Boolean, String],
      default: false
    }
  },
  computed: {
    cmOptions () {
      return {
        autoCloseBrackets: true, // 输入和退格时成对
        readOnly: this.readMode,
        foldGutter: true,
        hintOptions: {
          completeSingle: true
        },
        gutters: [
          'CodeMirror-linenumbers',
          'CodeMirror-foldgutter',
          'CodeMirror-lint-markers'
        ],
        lint: true,
        lineNumbers: true, // 显示行号
        lineWrapping: true, // 自动换行
        matchBrackets: true, // 括号匹配显示
        mode: this.language,
        smartIndent: true, // 是否智能缩进
        styleActiveLine: true, // 当前行高亮
        tabSize: 2,
        theme: 'lesser-dark' // 主题
      }
    }
  },
  data () {
    return {

    }
  },
  methods: {
    init () {
    },
    handleChange (v) {
      this.$emit('input', v)
    }
  }
}
</script>

<style lang="less">
.code-mirror-container{
  border-radius: 8px;
  border: 1px solid #D9D9D9;
  // min-height: 454px;
  overflow: hidden;

  // .CodeMirror {
  //   min-height: 454px;
  // }
}
</style>
