import request from '@/utils/request'

const urlPrefix = '/script-tasks'

export function executeScript (data) {
  return request.post(urlPrefix, data)
}

export function getScriptTaskList (params) {
  return request.get(`${urlPrefix}`, { params })
}

export function getScriptTask (id) {
  return request.get(`${urlPrefix}/${id}`)
}

export function getScriptTaskStatus (data) {
  return request.post('/celery/tasks', data)
}
